  /*********************************
COMPONENTS 
*********************************/
    
@import '_settings';              
@import '_colors';        
@import '_mixins';              
             
@import 'plugins/_bootstrap';     
@import 'plugins/_revo-slider'; 
@import 'plugins/_cubeportfolio';       
@import 'plugins/_owl-carousel';   
@import 'plugins/_fancybox';     
@import 'plugins/_icons'; 
@import 'plugins/_socicon';
@import 'plugins/_bootstrap-social';                                                                                              
@import 'plugins/_spinner';     
@import 'plugins/_minimalist-gallery';
@import 'plugins/_ilightbox';
@import 'plugins/_reveal-animate';