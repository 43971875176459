//Media queries breakpoints
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              					480px !default;

// Small screen / tablet  
$screen-sm-min:              					768px !default;

// Medium screen / desktop
$screen-md-min:              					992px !default;

// Large screen / wide desktop
$screen-lg-min:             	 				1200px !default;

// Large scree / wider desktop      
$screen-lg-med:                     			1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              					($screen-sm-min - 1) !default;  // 767px
$screen-sm-max:              					($screen-md-min - 1) !default;  // 991px
$screen-md-max:              					($screen-lg-min - 1) !default;  // 1199px

// Brand Colors:

$brand-default: 								darken(#b7c0ce, 10%);  
$brand-primary: 								#32c5d2;
$brand-success: 								#5dc09c;
$brand-info:	 								#4eabe6;
$brand-danger: 									#e7505a;
$brand-warning: 								#c8d046;

// General font settings
$general-font-color:  							darken(#677581, 5%);
$general-head-font-color:						#3f444a;
$general-bg-color:								#f7f7f7;
$general-border-color:							#eeeeee;
$general-input-border-color:					#d0d7de;
$general-font-family:   						'Roboto Condensed', sans-serif;
$general-font-size:   							17px;

// Forms Controls
$input-focus-border:							$general-input-border-color;
$input-checkbox-box-color:  					$general-input-border-color;
$input-checkbox-disabled-opacity:				0.7;

// Global link settings.
$general-link-color:            				#5b9bd1 !default;
$general-link-hover-color:            			#5b9bd1 !default;

// Font icons
$icon-size-normal:								16px;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar:            						9995 !default;
$zindex-navbar-above:      						9999 !default;
$zindex-navbar-fixed:      						1030 !default;
$zindex-dropdown:          						1000 !default;
$zindex-popover:           						1010 !default;
$zindex-tooltip:           						1030 !default;
$zindex-modal-backdrop:  						10040 !default;
$zindex-modal:             						10050 !default;
$zindex-top:									10100 !default;

// Layout Settings

$layout-fluid-padding:							0 100px;

//Theme colors
$theme-colors: (
	default:    	#32c5d2,

	green1:     	#52c9ba,
	green2:     	#5dc09c,
	green3:     	#84c68f,

	yellow1:     	#c8d046,
	yellow2:     	#c5bf66,
	yellow3:     	#c5b96b,

	red1:     		#cd6262,
	red2:     		#e7505a,
	red3:     		#d05163,

	purple1:     	#b771b0,
	purple2:   		#e7505a,
	purple3:   		#d05163,

	blue1:     		#6e8cd7,
	blue2:   		#5893dd,
	blue3:   		#57bfe1,  

	brown1:     	#8a7f68,
	brown2:   		#685e47,
	brown3:   		#7a6a61,

	dark1:     		#525e64,
	dark2:   		#31383c,
	dark3:   		#41515b,
);











