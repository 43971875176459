//Component colors

$component-colors: (
	white:	(color:#FFFFFF, inverse: #2f353b, muted: #2f353b),
	black:	(color:#000000, inverse: #ffffff, muted: #ffffff),
	regular:	(color:#7f8c97, inverse: #ffffff, muted: #ffffff),
	green:	(color:#32c5d2, inverse: #ffffff, muted: #ffffff),
	green-1:	(color:#52c9ba, inverse: #ffffff, muted: #ffffff),
	green-2:	(color:#5dc09c, inverse: #ffffff, muted: #ffffff),
	green-3:	(color:#84c68f, inverse: #ffffff, muted: #ffffff),
	red:	(color:#eb5d68, inverse: #ffffff, muted: #ffffff),
	red-1:	(color:#cd6262, inverse: #ffffff, muted: #ffffff),
	red-2:	(color:#e7505a, inverse: #ffffff, muted: #ffffff), 
	red-3:	(color:#d05163, inverse: #ffffff, muted: #ffffff),
	yellow:	(color:#FF6B57, inverse: #ffffff, muted: #ffffff),
	yellow-1:	(color:#c8d046, inverse: #ffffff, muted: #ffffff),
	yellow-2:	(color:#c5bf66, inverse: #ffffff, muted: #ffffff),
	yellow-3:	(color:#c5b96b, inverse: #ffffff, muted: #ffffff),
	blue:	(color:#3498DB, inverse: #ffffff, muted: #ffffff),
	blue-1:	(color:#5e9cd1, inverse: #ffffff, muted: #ffffff),
	blue-2:	(color:#5893dd, inverse: #ffffff, muted: #ffffff),
	blue-3:	(color:#57bfe1, inverse: #ffffff, muted: #ffffff),
	purple:	(color:#b771b0, inverse: #ffffff, muted: #ffffff),
	purple-1:	(color:#a962bb, inverse: #ffffff, muted: #ffffff),
	purple-2:	(color:#aa67a3, inverse: #ffffff, muted: #ffffff),
	purple-3:	(color:#ac3773, inverse: #ffffff, muted: #ffffff),
	brown:	(color:#8a7f68, inverse: #ffffff, muted: #ffffff),
	brown-1:	(color:#685e47, inverse: #ffffff, muted: #ffffff),
	brown-2:	(color:#7a6a61, inverse: #ffffff, muted: #ffffff),
	brown-3:	(color:#9d8b81, inverse: #ffffff, muted: #ffffff),
	dark:	(color:#2f353b, inverse: #ffffff, muted: #ffffff),
	dark-1:	(color:#525e64, inverse: #ffffff, muted: #ffffff),
	dark-2:	(color:#31383c, inverse: #ffffff, muted: #ffffff),
	dark-3:	(color:#41515b, inverse: #ffffff, muted: #ffffff),
	grey:	(color:#eeeeee, inverse: darken(#eeeeee, 50%), muted: darken(#eeeeee, 50%)),
	grey-1:	(color:#f7f7f7, inverse: darken(#f7f7f7, 50%), muted: darken(#f7f7f7, 50%)),
	grey-2:	(color:#677083, inverse: #ffffff, muted: #ffffff),
	grey-3:	(color:#7f8c97, inverse: #ffffff, muted: #ffffff)
);
